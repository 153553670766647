import { Inject, Injectable } from "@angular/core";


@Injectable()
export class CryptService {
    encrypt(value) {
        return (value);
    }

    decrypt(value) {
        return (value);
    }
}