// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCNC0UZrNrDQvDRrzBN1QdrhXmrAxWVtYk",
    authDomain: "queueingsystem-f0b9f.firebaseapp.com",
    databaseURL: "https://queueingsystem-f0b9f.firebaseio.com",
    projectId: "queueingsystem-f0b9f",
    storageBucket: "queueingsystem-f0b9f.appspot.com",
    messagingSenderId: "818198638664",
    appId: "1:818198638664:web:79af2b7db0bf256e9e287d",
    measurementId: "G-S0Y7QTXL0N"
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
